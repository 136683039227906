import './SalePersonDetails.css';
import logo from '../../../assests/images/odds-logo.png';
import { Button, Col, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatesNames } from '../../../store/actions/stateNamesAction';
import MetaData from '../../../layout/MetaData';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearErrors, salesProfileDetails, updateSalesProfile } from '../../../store/actions/salesActions/salesUsers';
import { useSnackbar } from 'notistack';

const SalePersonDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const { statesList } = useSelector((state) => state.statesNames);
    const { salesProfileDetail } = useSelector((state) => state.salesProfileDetail);
    const { error, isUpdated } = useSelector((state) => state.salesProfile);

    const [objId, setObjId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [dateBirth, setDateBirth] = useState('');
    const [ssNumber, setSsNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [abaNumber, setAbaNumber] = useState('');

    const [expiredLink, setExpiredLink] = useState(false);
    const [showForm, setShowForm] = useState(false);

    // handle Profile details submit
    const handleProfileSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("obj_id", objId);
        formData.set("customer_id", customerId);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("email", personEmail);
        formData.set("sale_address", address);
        formData.set("sale_street_address", streetAddress);
        formData.set("sale_city", city);
        formData.set("sale_state", state);
        formData.set("sale_zipcode", zipcode);
        formData.set("sale_date", dateBirth);
        formData.set("ss_number", ssNumber);
        formData.set("acc_full_name", accountName);
        formData.set("acc_number", accountNumber);
        formData.set("bank_aba", abaNumber);
        formData.set("account_type", accountType);

        dispatch(updateSalesProfile(formData));
    }

    useEffect(() => {
        const fetchUserId = () => {
            const pathParts = location.pathname.split('/');
            return pathParts[pathParts.length - 1];
        };

        const userId = fetchUserId();
        if (location.pathname === `/salepersondetails/${userId}`) {
            dispatch(salesProfileDetails(userId));
        }

        dispatch(getStatesNames())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Profile Error
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }

        // Profile Updated
        if(isUpdated){
            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });
            dispatch(clearErrors());

            setTimeout(()=> {
                navigate('/');
            }, 5000)
        }

    }, [dispatch, error, navigate, isUpdated, enqueueSnackbar])

    useEffect(() => {
        if(dateBirth){
            document.querySelector('#dateBirth').classList.add("hasvalue");
        }
    }, [dateBirth])


    useEffect(() => {
        if(salesProfileDetail && salesProfileDetail !== undefined && salesProfileDetail.length >= 1) {
            if(salesProfileDetail[0].bank_aba !== '' && salesProfileDetail[0].acc_number !== '' && salesProfileDetail[0].ss_number !== ''){
                setExpiredLink(true)
                setShowForm(true)
            } else{
                setPersonEmail(salesProfileDetail[0].sale_email);
                setCustomerId(salesProfileDetail[0].customer_id);
                setObjId(salesProfileDetail[0].user._id);
                setExpiredLink(false)
                setShowForm(true)
            }
        } else {
            setShowForm(false)
        }
    }, [salesProfileDetail])

    return(
        <>
            <MetaData
                title="Sale Person Details"
            />

            <div className='sales_persons_details w-full'>
                <div className='flex flex-col items-center w-full mx-auto max-w-screen-992'>
                    <div className='flex-1 w-full'>
                        <div className='sales_details_logo p-20px text-center'>
                            <img src={logo} alt='ODDS Logo' className='w-full max-w-400 m-auto' />
                        </div>
                    </div>

                    { showForm ? 

                    expiredLink ?
                        <div className='flex-1 w-full mt-30px'>
                            <p className='expiredLink'>This link has been expired.</p>
                        </div>
                    :

                        <div className='flex-1 w-full mt-30px'>
                            <Form className="sales-profile-form sales_details_form" method="post" onSubmit={handleProfileSubmit}>

                                <h2 className="form-heading">Personal Details</h2>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col} controlId="firstname">
                                        <Form.Control type="text" placeholder="First Name" name="firstname" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="lastname">
                                        <Form.Control type="text" placeholder="Last Name" name="lastname" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col} controlId="address">
                                        <Form.Control type="text" placeholder="Street Number and Name" name="address" required value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="streetAddress">
                                        <Form.Control type="text" placeholder="Apartment/Unit/Suite Number" name="streetAddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col} controlId="city">
                                        <Form.Control type="text" placeholder="City" name="city" required value={city} onChange={(e) => setCity(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="state">
                                        <Form.Select required className="form-control" value={state} onChange={(e) => setState(e.target.value)} >
                                            <option value={""}>Select state</option>
                                            {statesList && statesList.length >= 1 && statesList.map((state,i) => (
                                                <option key={i} value={state.state_name} id={state.state_code}>{state.state_name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="zipcode">
                                        <Form.Control type="text" placeholder="Zipcode" name="zipcode" required value={zipcode} onChange={(e) => setZipcode(e.target.value)} pattern="[0-9]{5}" maxLength={5} />
                                    </Form.Group>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col}>
                                        <Form.Control type="date" data-placeholder="Date of Birth" name="dateBirth" required value={dateBirth} id="dateBirth" onChange={(e) => setDateBirth(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="ssNumber">
                                        <Form.Control type="text" placeholder="Social Security Number" name="ssNumber" required value={ssNumber} onChange={(e) => setSsNumber(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <h2 className="form-heading mt-30px">Bank Details</h2>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col} controlId="accountType">
                                        <Form.Select required className="form-control" value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                                            <option value="">Account Type</option>
                                            <option value={"Savings"}>Savings</option>
                                            <option value={"Checking"}>Checking</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="accountNumber">
                                        <Form.Control type="text" placeholder="Bank Account Number" name="accountNumber" required value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                                    <Form.Group as={Col} controlId="accountName">
                                        <Form.Control type="text" placeholder="Name of Bank" name="accountName" required value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="abaNumber">
                                        <Form.Control type="text" placeholder="Bank ABA Number" name="abaNumber" required value={abaNumber} onChange={(e) => setAbaNumber(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <div className='flex flex-col w-full'>
                                    <Button type="submit" className="sales_details_submit submit-btn">Submit</Button>
                                </div>

                            </Form>
                        </div> 
                    
                    :
                        null
                    }
                </div>
            </div>
        </>
    )
}

export default SalePersonDetails