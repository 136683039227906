import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap"
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_BUSINESS_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { clearErrors, detailBusinessUser, updateBusinessProfile, updateBusinessProfileCC } from "../../../../store/actions/businessActions/businessUsers";

const MyProfilePayment = () =>{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);
    const { error, isUpdated } = useSelector((state) => state.businessProfile);

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [emailAdd, setEmailAdd] = useState('');

    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [restaurantCity, setRestaurantCity] = useState('');
    const [restaurantState, setRestaurantState] = useState('');
    const [restaurantZipcode, setRestaurantZipcode] = useState('');
    const [restaurantCountry, setRestaurantCountry] = useState('');
    const [restaurantStreetAddress, setRestaurantStreetAddress] = useState('');
    const [restaurantLat, setRestaurantLat] = useState('');
    const [restaurantLong, setRestaurantLong] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [restaurantPhone, setRestaurantPhone] = useState('');
    const [restaurantEmailAdd, setRestaurantEmailAdd] = useState('');
    const [accountNumber, setAccountNumber] = useState(''); 
    const [ccExpirationMonth, setCcExpirationMonth] = useState(''); 
    const [ccExpirationYear, setCcExpirationYear] = useState('');
    const [ccCvv, setCcCvv] = useState(''); 
    const [years, setYears] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("restaurant_email", restaurantEmailAdd);
        formData.set("restaurant_phone", restaurantPhone);
        formData.set("restaurant_address", restaurantAddress);
        formData.set("restaurant_street_address", restaurantStreetAddress);
        formData.set("restaurant_city", restaurantCity);
        formData.set("restaurant_state", restaurantState);
        formData.set("restaurant_zipcode", restaurantZipcode);
        formData.set("restaurant_country", restaurantCountry);
        formData.set("restaurant_lat", restaurantLat);
        formData.set("restaurant_long", restaurantLong);
       
        dispatch(updateBusinessProfile(formData));

         
        if(businessUserDetail && businessUserDetail.businessdata.cc_number && businessUserDetail.businessdata.cc_number !== accountNumber){
            updateCC()
        } else if(businessUserDetail && businessUserDetail.businessdata.cc_cvv && businessUserDetail.businessdata.cc_cvv !== ccCvv){
            updateCC()
        } else if(businessUserDetail && businessUserDetail.businessdata.cc_expiration && businessUserDetail.businessdata.cc_expiration !== ccExpirationMonth+"/"+ccExpirationYear){
            updateCC()
        }

    }

    const updateCC = () => {
        const formDataCC = new FormData();
        formDataCC.set("cc_number", accountNumber);
        formDataCC.set("cc_expiration", ccExpirationMonth+"/"+ccExpirationYear);
        formDataCC.set("cc_cvv", ccCvv);
        formDataCC.set("obj_id", objectId);
        formDataCC.set("customer_id", customerId);
                
        dispatch(updateBusinessProfileCC(formDataCC))
    }

    // address autocomplete
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {
                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setRestaurantStreetAddress(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setRestaurantZipcode(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setRestaurantCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setRestaurantState(state)
        
                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setRestaurantCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setRestaurantLat(lat);
                setRestaurantLong(lng);
            }
          setRestaurantAddress(place.formatted_address);
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "us" },
        },
    });

     // handle restaurant mobile number
     const handleRestaurantPhone = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setRestaurantPhone(value);
    }

    useEffect(() => {

        // prefill user details
        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
            setObjectId(businessUserDetail.user._id)
            setCustomerId(businessUserDetail.user.customer_id);
            setEmailAdd(businessUserDetail.user.email);
            setFirstName(businessUserDetail.user.firstname);
            setLastName(businessUserDetail.user.lastname);
            setRestaurantEmailAdd(businessUserDetail.businessdata.restaurant_email ? businessUserDetail.businessdata.restaurant_email : '');
            setRestaurantPhone(businessUserDetail.businessdata.restaurant_phone ? businessUserDetail.businessdata.restaurant_phone : '');
            setRestaurantAddress(businessUserDetail.businessdata.restaurant_address ? businessUserDetail.businessdata.restaurant_address : '');
            setRestaurantStreetAddress(businessUserDetail.businessdata.restaurant_street_address ? businessUserDetail.businessdata.restaurant_street_address : '');
            setRestaurantCity(businessUserDetail.businessdata.restaurant_city ? businessUserDetail.businessdata.restaurant_city : '');
            setRestaurantState(businessUserDetail.businessdata.restaurant_state ? businessUserDetail.businessdata.restaurant_state : '');
            setRestaurantZipcode(businessUserDetail.businessdata.restaurant_zipcode ? businessUserDetail.businessdata.restaurant_zipcode : '');
            setRestaurantCountry(businessUserDetail.businessdata.restaurant_country ? businessUserDetail.businessdata.restaurant_country : '');
            setRestaurantLat(businessUserDetail.businessdata.restaurant_lat ? businessUserDetail.businessdata.restaurant_lat : '');
            setRestaurantLong(businessUserDetail.businessdata.restaurant_long ? businessUserDetail.businessdata.restaurant_long : '');
            
            setCcCvv(businessUserDetail.businessdata.cc_cvv ? businessUserDetail.businessdata.cc_cvv : '');

            if(businessUserDetail.businessdata.cc_number){
                if(businessUserDetail.businessdata.cc_number.toString().length > 8){
                    const cardNumberString = businessUserDetail.businessdata.cc_number.toString();
                    const visibleStart = cardNumberString.slice(0, 4);
                    const visibleEnd = cardNumberString.slice(-4);
                    const maskedSection = 'X'.repeat(cardNumberString.length - 8);
                    setAccountNumber(visibleStart+maskedSection+visibleEnd)
                } else {
                    setAccountNumber(businessUserDetail.businessdata.cc_number);
                }
                
            } else {
                setAccountNumber('');
            }

            if(businessUserDetail.businessdata.cc_expiration){
                const expiration = businessUserDetail.businessdata.cc_expiration.split("/");
                setCcExpirationMonth(expiration[0])
                setCcExpirationYear(expiration[1])
            } else {
                setCcExpirationMonth('')
                setCcExpirationYear('')
            }

        } 

        // Year dropdown
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let i = 0; i < 15; i++) {
            yearsArray.push(currentYear + i);
        }
        setYears(yearsArray);

        // Profile Error
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
                
        // Profile updated
        if (isUpdated) {
        
            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });
        
            var userDetails = {
                obj_id: objectId,
                email: emailAdd,
                customer_id: customerId,
            }
            dispatch(detailBusinessUser(userDetails))
            dispatch({ type: UPDATE_BUSINESS_RESET });
        }

    }, [dispatch, businessUserDetail, enqueueSnackbar, error, isUpdated, objectId, customerId, emailAdd, loading])

    // get year as 2 digit
    const getTwoDigitYear = (year) => year % 100;

    return(
        <div className="p-2.5">
            <h4 className="flex justify-between mb-20px">Profile <span></span></h4>

            <Form method="post" onSubmit={handleSubmit} className="business_profile_form">
                <div className="flex flex-col md:flex-row -mx-12px">
                    <div className="w-full md:w-1/2 px-12px">

                        <Form.Group className="mb-15px" controlId="restaurant_address">
                            <Form.Control ref={ref}  type="text" placeholder="Address*" name="restaurant_address" required defaultValue={restaurantAddress} />
                            <input type="hidden" name="restaurant_street_address" defaultValue={restaurantStreetAddress} />
                            <input type="hidden" name="restaurant_country" defaultValue={restaurantCountry} />
                            <input type="hidden" name="restaurant_lat" defaultValue={restaurantLat} />
                            <input type="hidden" name="restaurant_long" defaultValue={restaurantLong} />
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_city">
                            <Form.Control type="text" placeholder="City*" name="restaurant_city" required value={restaurantCity} onChange={(e) => setRestaurantCity(e.target.value)}/>
                        </Form.Group>

                        <Form.Select name="restaurant_state" id="restaurant_state" className="mb-15px" value={restaurantState} onChange={(e) => setRestaurantState(e.target.value)} required>
                            <option value="">State</option>
                            {businessUserDetail && businessUserDetail.status === 1 && businessUserDetail.StateNameslist.map((item,i) => (
                                <option value={item.state_name} id={item.state_code} key={i}>{item.state_name}</option>
                            ))}
                        </Form.Select>

                        <Form.Group className="mb-15px" controlId="restaurant_zipcode">
                            <Form.Control type="text" placeholder="Zipcode*" name="restaurant_zipcode" required value={restaurantZipcode} onChange={(e) => setRestaurantZipcode(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_phone">
                            <Form.Control type="tel" placeholder="Phone*" name="restaurant_phone" required value={restaurantPhone} onChange={handleRestaurantPhone} minLength={10} maxLength={10}/>
                        </Form.Group>
                    </div>

                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px" controlId="first_name">
                            <Form.Control type="text" name="first_name" placeholder="First Name*" required value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="last_name">
                            <Form.Control type="text" placeholder="Last Name*" name="last_name" required value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_email">
                            <Form.Control type="email" placeholder="Email*" name="restaurant_email" required value={restaurantEmailAdd} onChange={(e) => setRestaurantEmailAdd(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="account_number">
                            <Form.Control type="text" placeholder="Card Number*" name="account_number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} maxLength={16} required />
                        </Form.Group>

                        <div className="flex flex-col sm:flex-row flex-wrap gap-2.5 sm:items-center mb-15px select-flex">
                            <Form.Select name="exp_month" id="exp_month" value={ccExpirationMonth} onChange={(e) => setCcExpirationMonth(e.target.value)} required>
                                <option value="">MM</option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Form.Select>

                            <label>Exp/Year</label>

                            <Form.Select name="cc_expiration_year" id="cc_expiration_year" value={ccExpirationYear}  onChange={(e) => setCcExpirationYear(e.target.value)} required>
                                <option value="">YY</option>
                                {years.map((year) => (
                                    <option key={year} value={getTwoDigitYear(year).toString().padStart(2, '0')}>
                                     {year}
                                    </option>
                                ))}
                            </Form.Select>

                            <label>CVV</label>
                            
                            <Form.Group controlId="cc_cvv">
                                <Form.Control type="password" placeholder="CVV" name="cc_cvv" value={ccCvv} onChange={(e) => setCcCvv(e.target.value)} maxLength={4} required/>
                            </Form.Group> 
                            
                            <Form.Group className="text-right flex-1">
                                <Button type="submit" className="btn-consumer-profile">Submit</Button>
                            </Form.Group>
                        </div>

                    </div>

                </div>
            </Form>
        </div>
    )
}

export default MyProfilePayment