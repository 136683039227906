import { GET_STATES_FAIL, GET_STATES_REQUEST, GET_STATES_SUCCESS } from "../types/stateNames";

export const getStatesNamesReducer = (state = { statesList: {} }, { type, payload }) => {
    switch (type) {
        case GET_STATES_REQUEST:
            return {
                loading: true,
            };
        case GET_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                statesList: payload,
            };
        case GET_STATES_FAIL:
            return {
                ...state,
                loading: false,
                statesList: null,
                error: payload,
            };
        default:
            return state;
    }
};