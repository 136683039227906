import axios from "axios";
import { CLEAR_ERRORS, NEW_CONSUMER_ORDER_FAIL, NEW_CONSUMER_ORDER_REQUEST, NEW_CONSUMER_ORDER_SUCCESS } from "../../types/consumerTypes/consumerOrders";

// New consumer Order
export const newConsumerOrderData = (order) => async (dispatch) => {
    try {
        dispatch({ type: NEW_CONSUMER_ORDER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/createconsumerorder`,
            order,
            config
        );

        dispatch({
            type: NEW_CONSUMER_ORDER_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: NEW_CONSUMER_ORDER_FAIL,
            payload: error.response.data,
        });
    }
};


// Clear All Errors
export const clearConsumerErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}