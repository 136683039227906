import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SpecialDelay = () => {

    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);

    const [specialDelay, setSpecialDelay] = useState(false);
    const [specialDelayMsg, setSpecialDelayMsg] = useState('');

    useEffect(() => {
    
        if(businessUserDetail && businessUserDetail.status === 1 ){
            if(businessUserDetail.businessdata.special_delay === 1){
                setSpecialDelay(true); 
                setSpecialDelayMsg(businessUserDetail.special_delay_msg);
            }
        }
            
    }, [businessUserDetail]);

    return(
        <>
        
            {specialDelay && 
                <div className="special_delay_popup content" dangerouslySetInnerHTML={{__html: specialDelayMsg ? specialDelayMsg : ''}}>
                </div>
            }
        </>
    )
}

export default SpecialDelay