import { Button, Col, Form } from "react-bootstrap";
import MetaData from "../../../layout/MetaData";
import shape4 from '../../../assests/images/business/shape4.png';
import shape3 from '../../../assests/images/business/shape3.png';
import { usePlacesWidget } from "react-google-autocomplete";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrors, registerBusinessUser, setBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { registerType } from "../../../store/actions/registerTypesAction";
import { RESET_BUSINESS } from "../../../store/types/businessTypes/businessUsers";

const ConsumerRegister = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { types } = useSelector((state) => state.registerType);
    const { userDetail } = useSelector((state) => state.userDetails);

    const { isAuthenticated, error, user } = useSelector((state) => state.businessUser);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [termsValue, setTermsValue] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [restaurantStreetAddress, setRestaurantStreetAddress] = useState('');
    const [restaurantCity, setRestaurantCity] = useState('');
    const [restaurantState, setRestaurantState] = useState('');
    const [restaurantZipcode, setRestaurantZipcode] = useState('');
    const [restaurantCountry, setRestaurantCountry] = useState('');
    const [restaurantLat, setRestaurantLat] = useState('');
    const [restaurantLong, setRestaurantLong] = useState('');

    const messageRef = useRef(null);

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {
                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setRestaurantStreetAddress(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setRestaurantZipcode(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setRestaurantCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setRestaurantState(state)
        
                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setRestaurantCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setRestaurantLat(lat);
                setRestaurantLong(lng);
            }
          setRestaurantAddress(place.formatted_address);
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "us" },
        },
    });


    const handleSubmit = (e) => {
        e.preventDefault();

        if(password !== confirmPassword){
            setErrorMessage("Password not matched");
            messageRef.current.focus();
        }
        if(restaurantStreetAddress === ''){
            setErrorMessage("Choose Address from dropdown");
            messageRef.current.focus();
            return
        }

        if(restaurantState === ''){
            setErrorMessage("Choose Complete Address from dropdown");
            messageRef.current.focus();
            return
        }
        if(restaurantCity === '' ){
            setErrorMessage("Choose Complete Address from dropdown");
            messageRef.current.focus();
            return
        }
        if(restaurantZipcode === ''){
            setErrorMessage("Choose Complete Address from dropdown");
            messageRef.current.focus();
            return
        }
        
        setTimeout(()=> {
            setErrorMessage("");
        }, 5000)

        var group = types.filter(item => item.name === 'consumer');
        var customer_group_id = group[0].customer_group_id;

        const formData = new FormData();
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("email", email);
        formData.set("telephone", telephone);
        formData.set("password", password);
        formData.set("customer_group_id", customer_group_id);
        formData.set("status", 1);
        formData.set("restaurant_address", restaurantAddress);
        formData.set("restaurant_street_address", restaurantStreetAddress);
        formData.set("restaurant_city", restaurantCity);
        formData.set("restaurant_state", restaurantState);
        formData.set("restaurant_zipcode", restaurantZipcode);
        formData.set("restaurant_country", restaurantCountry);
        formData.set("restaurant_lat", restaurantLat);
        formData.set("restaurant_long", restaurantLong);

        dispatch(registerBusinessUser(formData));
    }

    useEffect(() => {

        if (error) {
            setErrorMessage(error);
            messageRef.current.focus();

            setTimeout(()=> {
                setErrorMessage("");
                dispatch(clearErrors());
            }, 10000)
            
        }

        if (isAuthenticated) {
            var userDetails = {
                userId: user._id,
                userEmail: user.email,
                customerGroup: user.customer_group_id,
                customerId: user.customer_id,
                userName: user.firstname,
            }
            dispatch(setBusinessSession(userDetails))

            dispatch({ type: RESET_BUSINESS});
        }

        if(userDetail && userDetail.userId){
           
            console.log(userDetail) 
            navigate('/consumerdashboard');
        }
        
        dispatch(registerType());
        
    }, [dispatch, isAuthenticated, error, userDetail, navigate, user])

    return(
        <>

            {/* SEO Meta */}
            <MetaData
                title={"Create Business Account"}
            />

            {/* Register section */}
            <section className="business-register relative overflow-hidden">
                <div className="max-w-screen-1xl mx-auto py-100px flex flex-col relative">
                    <div className="px-15px">
                        <h1 className="text-[58px] font-semibold text-center mb-9">Create Account</h1>
                        <p className="text-2xl text-center mb-0 pb-15px">You're moments away from being able to request drivers on demand.<br />
                        Enter your details below to get started!</p>
                    </div>

                    <div tabIndex="-1" ref={messageRef} className="message text-center">
                        {errorMessage && (
                            <div className="text-red-500">{errorMessage}</div>
                        )}
                    </div>
                    
                    <div className="mt-20px px-15px">
                        <div className="form business-login-register max-w-700 mx-auto py-8 md:py-10 px-8 md:px-16">
                            <Form className="form-login-regiser form" onSubmit={handleSubmit} method="post">
                                
                                <div className="flex flex-col lg:flex-row gap-[20px] mt-20px">
                                    <Form.Group as={Col} controlId="firstname">
                                        <Form.Control type="text" placeholder="First Name" name="firstname" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="lastname">
                                        <Form.Control type="text" placeholder="Last Name" name="lastname" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </Form.Group>
                                </div>

                                <Form.Group className="mt-20px" controlId="email">
                                    <Form.Control type="email" placeholder="Email" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                <div className="flex flex-col lg:flex-row gap-[20px] mt-20px">
                                    <Form.Group as={Col} controlId="password">
                                        <Form.Control type="password" placeholder="Create Password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} minLength={8} maxLength={15} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="confirm_password">
                                        <Form.Control type="password" placeholder="Confirm Password" name="confirm_password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} minLength={8} maxLength={15} />
                                    </Form.Group>
                                </div>

                                <Form.Group className="mt-20px" controlId="telephone">
                                    <Form.Control type="tel" placeholder="Phone Number" name="telephone" required minLength={9} maxLength={10} value={telephone} onChange={(e) => setTelephone(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mt-20px" controlId="restaurant_address">
                                    <Form.Control ref={ref} type="text" placeholder="Home Address For Billing" name="restaurant_address" required />
                                </Form.Group>

                                <Form.Group className="mt-20px">
                                    <Form.Check
                                        label="Check here to Opt In to Receive Text and Email Notifications."
                                        name="check-term"
                                        type="checkbox"
                                        id="check-term"
                                        value={termsValue} 
                                        onChange={() => setTermsValue(!termsValue)}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-20px text-center">
                                    <Button type="submit" className="btn-submit mt-10">Submit</Button>
                                </Form.Group>

                                <p className="get-started text-xl mt-20px text-center">Already Register? <a href="/consumerlogin" className="btn-stated text-base text-primary-blue">Login</a></p>

                            </Form>
                        </div>
                    </div>

                    <img src={shape4} alt="Shape4" className="max-w-100 absolute -top-3.5 -z-10" />
                    <img src={shape4} alt="Shape5" className="max-w-100 absolute bottom-[100px] -z-10 filter-color" />
                    
                </div>
                <img src={shape3} alt="Shape3" className="max-w-200 absolute top-0 bottom-0 -right-14 m-auto -z-10" />
            </section>
        </>
    )
}

export default ConsumerRegister