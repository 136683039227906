import { combineReducers } from "redux";
import { registerTypeReducer } from "./registerTypeReducer";
import { businessDetailsReducer, businessProfileReducer, businessUserReducer, setBusinessSession } from "./businessReducers/businessUsers";
import { deliveredOrdersReducer, generateOrdersReducer, myInternalOrdersReducer, newInternalOrderReducer, orderInvoiceReducer, sendDriverOrdersReducer } from "./businessReducers/businessOrders";
import { getScanDriversReducers } from "./businessReducers/scanDrivers";
import { getChatAdminReducers } from "./businessReducers/chatAdmins";
import { getAdsImagesReducer, getPhoneCountReducer, getSmsQueueReducer, uploadAdsImageReducer } from "./businessReducers/MarketingAds";
import { downloadPhoneTripsReducer, getTripsRequestedReducers } from "./businessReducers/tripsRequested";
import { getCommissionYearReducer, getDeliveryYearReducer, salesCommissionReportReducer, salesDeliveryReportReducer, salesDetailsReducer, salesProfileDetailsReducer, salesProfileReducer } from "./salesReducers/salesUsers";
import { addSalesReportReducer, getSalesReportReducer } from "./salesReducers/salesReport";
import { getStatesNamesReducer } from "./stateNamesReducer";
import { newConsumerOrderReducer } from "./consumerReducers/consumerOrders";

export default combineReducers({
    statesNames: getStatesNamesReducer,
    registerType : registerTypeReducer,
    scanDrivers: getScanDriversReducers,
    chatAdmin: getChatAdminReducers,
    businessUser: businessUserReducer,
    businessProfile: businessProfileReducer,
    businessUserDetails: businessDetailsReducer,
    userDetails: setBusinessSession,
    newInternalOrder: newInternalOrderReducer,
    myInternalOrders: myInternalOrdersReducer,
    ordersInvoice: orderInvoiceReducer,
    phoneNoCount: getPhoneCountReducer,
    adsData: uploadAdsImageReducer,
    adsImageLists: getAdsImagesReducer,
    smsQueueLists: getSmsQueueReducer,
    downloadPhones: downloadPhoneTripsReducer,
    deliveredOrders: deliveredOrdersReducer,
    tripOrders: getTripsRequestedReducers,
    sendDrivers: sendDriverOrdersReducer,
    generateOrders: generateOrdersReducer,
    
    // consumer users 
    newConsumerOrder: newConsumerOrderReducer,

    // sales users 
    salesUserDetails: salesDetailsReducer,
    salesProfile: salesProfileReducer,
    salesDeliveryReport: salesDeliveryReportReducer,
    salesAddReport: addSalesReportReducer,
    salesReport: getSalesReportReducer,
    salesCommissionReport: salesCommissionReportReducer,
    commissionYears: getCommissionYearReducer,
    deliveryYears: getDeliveryYearReducer,
    salesProfileDetail: salesProfileDetailsReducer,
})