import { CLEAR_ERRORS, NEW_CONSUMER_ORDER_FAIL, NEW_CONSUMER_ORDER_REQUEST, NEW_CONSUMER_ORDER_SUCCESS } from "../../types/consumerTypes/consumerOrders";

// new order reducer
export const newConsumerOrderReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case NEW_CONSUMER_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_CONSUMER_ORDER_SUCCESS:
            return {
                loading: false,
                success: payload.message,
                consOrder: payload,
            };
        case NEW_CONSUMER_ORDER_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: null,
            };
        default:
            return state;
    }
};