import { CLEAR_ERRORS, COMMISSION_YEAR_FAIL, COMMISSION_YEAR_REQUEST, COMMISSION_YEAR_SUCCESS, SALES_COMMISSION_REPORT_FAIL, SALES_COMMISSION_REPORT_REQUEST, SALES_COMMISSION_REPORT_SUCCESS, SALES_DELIVERY_REPORT_FAIL, SALES_DELIVERY_REPORT_REQUEST, SALES_DELIVERY_REPORT_SUCCESS, SALES_PROFILE_DETAIL_FAIL, SALES_PROFILE_DETAIL_REQUEST, SALES_PROFILE_DETAIL_SUCCESS, SALES_USER_DETAIL_FAIL, SALES_USER_DETAIL_REQUEST, SALES_USER_DETAIL_SUCCESS, UPDATE_SALES_FAIL, UPDATE_SALES_PASSWORD_FAIL, UPDATE_SALES_PASSWORD_REQUEST, UPDATE_SALES_PASSWORD_RESET, UPDATE_SALES_PASSWORD_SUCCESS, UPDATE_SALES_REQUEST, UPDATE_SALES_RESET, UPDATE_SALES_SUCCESS } from "../../types/salesTypes/salesUsers";

// sales person user details
export const salesDetailsReducer = (state = { salesUserDetail: {} }, { type, payload }) => {
    switch (type) {
        case SALES_USER_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case SALES_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                salesUserDetail: payload,
            };
        case SALES_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                salesUserDetail: null,
                error: payload,
            };
        default:
            return state;
    }
}

// Sales Profile reducer
export const salesProfileReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_SALES_REQUEST:
        case UPDATE_SALES_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case UPDATE_SALES_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
         case UPDATE_SALES_RESET:
            return {
                ...state,
                isUpdated: false,
                error: null,
            }
        // Password 
        case UPDATE_SALES_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isPassUpdated: payload,
            };
        case UPDATE_SALES_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                errorPass: payload,
            }
        case UPDATE_SALES_PASSWORD_RESET:
            return {
                ...state,
                isPassUpdated: false,
                errorPass: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                isUpdated: false,
                isPassUpdated: false,
                errorPass: null,
            };
        default:
            return state;
    }
};

// sales person delivery report
export const salesDeliveryReportReducer = (state = { salesDelivery: {} }, { type, payload }) => {
    switch (type) {
        case SALES_DELIVERY_REPORT_REQUEST:
            return {
                loading: true,
            };
        case SALES_DELIVERY_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                salesDelivery: payload,
            };
        case SALES_DELIVERY_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                salesDelivery: null,
                error: payload,
            };
        default:
            return state;
    }
}

// sales person commission report
export const salesCommissionReportReducer = (state = { salesCommission: {} }, { type, payload }) => {
    switch (type) {
        case SALES_COMMISSION_REPORT_REQUEST:
            return {
                loading: true,
            };
        case SALES_COMMISSION_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                salesCommission: payload,
            };
        case SALES_COMMISSION_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                salesCommission: null,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Get Commission Year Reducer
export const getCommissionYearReducer = (state = { commissionYear: {} }, { type, payload }) => {
    switch (type) {
        case COMMISSION_YEAR_REQUEST:
            return {
                loading: true,
            };
        case COMMISSION_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                commissionYear: payload,
            };
        case COMMISSION_YEAR_FAIL:
            return {
                ...state,
                loading: false,
                commissionYear: null,
                error: payload,
            };
        default:
            return state;
    }
}

// Get Delivery Year Reducer
export const getDeliveryYearReducer = (state = { deliveryYearArr: {} }, { type, payload }) => {
    switch (type) {
        case COMMISSION_YEAR_REQUEST:
            return {
                loading: true,
            };
        case COMMISSION_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveryYearArr: payload,
            };
        case COMMISSION_YEAR_FAIL:
            return {
                ...state,
                loading: false,
                deliveryYearArr: null,
                error: payload,
            };
        default:
            return state;
    }
}

// sales profile details reducer
export const salesProfileDetailsReducer = (state = { salesProfileDetail: {} }, { type, payload }) => {
    switch (type) {
        case SALES_PROFILE_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case SALES_PROFILE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                salesProfileDetail: payload,
            };
        case SALES_PROFILE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                salesProfileDetail: null,
                error: payload,
            };
        default:
            return state;
    }
}