import { useEffect, useRef, useState } from "react";
import { Col, Form, Table } from "react-bootstrap";
import csvIcon from '../../../../assests/images/csv-icon.png';
import pdfIcon from '../../../../assests/images/pdf-icon.png';
import { useDispatch, useSelector } from "react-redux";
import { commissionReportSales, commissionYearSales } from "../../../../store/actions/salesActions/salesUsers";
import moment from "moment";
import generatePDF, { Margin } from 'react-to-pdf';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

const CommissionReport = () => {

    const dispatch = useDispatch();

    const reportCommissionRef = useRef(null);

    const { salesUserDetail, loading } = useSelector((state) => state.salesUserDetails);
    const { salesCommission } = useSelector((state) => state.salesCommissionReport);
    const { commissionYear } = useSelector((state) => state.commissionYears);

    const [deliveryYear, setDeliveryYear] = useState('');
    const [deliveryMonth, setDeliveryMonth] = useState('');

    // submit form
    const handleReportSubmit = (e) => {
        e.preventDefault();
        dispatch(commissionReportSales(salesUserDetail.user.customer_id, deliveryYear, deliveryMonth));
    }

    // handle year change event
    const handleYearChange = (e) => {
        setDeliveryYear(e.target.value);
        dispatch(commissionReportSales(salesUserDetail.user.customer_id, e.target.value, deliveryMonth));
    }

    // handle month change event
    const handleMonthChange = (e) => {
        setDeliveryMonth(e.target.value);
        dispatch(commissionReportSales(salesUserDetail.user.customer_id, deliveryYear, e.target.value));
    }

    // PDF options
    const options = {
        method: 'open',
        filename: 'commission_report.pdf',
        page: {
           margin: Margin.MEDIUM,
           format: 'A4',
           orientation: 'portrait',
        },
        overrides: {
            canvas: {
                onclone: async function (doc) {
                    const tdList = doc.querySelectorAll(".report_table tr td");
                    for (let i = 0; i < tdList.length; i++) {
                        tdList[i].style.padding = "0 8px 18px";
                    }
                    const thList = doc.querySelectorAll(".report_table tr th");
                    for (let i = 0; i < thList.length; i++) {
                        thList[i].style.padding = "0 8px 18px";
                    }
                }
            }
        },
    };

    // sales commission export data
    const mappedData = salesCommission && salesCommission.transaction && salesCommission.transaction.length >= 1 && salesCommission.transaction.map(item => {
        return [
            moment(item.salesperson_transaction_date_added).format("MM/DD/Y"),
            item.salesperson_transaction_restaurant,
            item.salesperson_transaction_type === 1 ? 'New Sale' : item.salesperson_transaction_type === 2 ? 'Residual' : item.salesperson_transaction_type === 3 ? 'Bonus' : '-',
            '$' + item.salesperson_transaction_amount.toFixed(2),
        ];
    })

    // Export as CSV
    const generateCSV = () => {
       
        const worksheet = XLSX.utils.json_to_sheet(mappedData, { origin: 'A2', skipHeader: true });
        
        const Heading = [
            ['Date', 'Business Name', 'Type', 'Commision']
        ];

        XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });

        // Convert the worksheet to CSV format
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        // Create a Blob from the CSV string and download
        const blob = new Blob([csv], { type: 'text/csv' });
        FileSaver.saveAs(blob, `commission_report_${moment().unix()}.csv`);
        
    }

    useEffect(() => {
        if(loading === false && salesUserDetail && salesUserDetail.status === 1){
            dispatch(commissionReportSales(salesUserDetail.user.customer_id, '', ''))
            dispatch(commissionYearSales(salesUserDetail.user.customer_id))
        }
        
    }, [dispatch, loading, salesUserDetail])


    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <div className="reports_filter_section">
                <div className="reports_filter_left">
                    <Form className="sales-add-report-form business_profile_form" method="post" onSubmit={handleReportSubmit}>

                        <div className="flex flex-col md:flex-row gap-[20px]">
                            <Form.Group as={Col} controlId="report_delivery_year_comm">
                                <Form.Label>Select Year</Form.Label>
                                <Form.Select name="report_delivery_year_comm" value={deliveryYear} onChange={(e) => handleYearChange(e)} className="report_filter_input">
                                    <option value="">Select Year</option>
                                    {commissionYear && commissionYear.length >= 1 &&
                                        commissionYear.map((year,i) => (
                                            <option value={year} key={i}>{year}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="report_delivery_month_comm">
                                <Form.Label>Select Month</Form.Label>
                                <Form.Select name="report_delivery_month_comm" value={deliveryMonth} onChange={(e) => handleMonthChange(e)} className="report_filter_input">
                                    <option value="">Select Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                            </Form.Group>

                        </div>

                    </Form>
                </div>

                <div className="reports_filter_right">
                    <img src={csvIcon} alt="CSV" onClick={() => generateCSV()} />
                    <img src={pdfIcon} alt="PDF" onClick={() => generatePDF(reportCommissionRef, options)} />
                </div>
            </div>

            <Table ref={reportCommissionRef} className="report_table" id="delivery_report_table">
                <thead>
                    <tr>
                        <th className="text-center pdf_report" style={{width: '22%', verticalAlign: 'middle'}}>Date</th>
                        <th style={{width: '28%'}}>Business Name</th>
                        <th className="text-center pdf_report" style={{width: '25%'}}>Type</th>
                        <th className="text-center" style={{width: '25%'}}>Commision</th>
                    </tr>
                </thead>
                <tbody>
                    {salesCommission && salesCommission.transaction && salesCommission.transaction.length >= 1 ? 
                        salesCommission.transaction.map((item,i) => (
                            <tr className={ i%2 ===0 ? "grey": "white" } key={i}>
                                <td data-label="Date" className="text-center">{moment(item.salesperson_transaction_date_added).format("MM/DD/Y")}</td>
                                <td data-label="Business Name">{item.salesperson_transaction_restaurant}</td>
                                <td data-label="Type" className="text-center">{item.salesperson_transaction_type === 1 ? 'New Sale' : item.salesperson_transaction_type === 2 ? 'Residual' : item.salesperson_transaction_type === 3 ? 'Bonus' : '-'}</td>
                                <td data-label="Commision" className="text-center">$ {item.salesperson_transaction_amount.toFixed(2)}</td>
                            </tr>
                        ))
                    :
                        <tr>
                            <td colSpan={4}>No Record Found</td>
                        </tr>
                    }
                   
                    <tr className="tfoot">
                        <td colSpan="3" className="text-right hide-mobile">Total:</td>
                        <td data-label="Total Commision" className="text-center">$ {salesCommission && salesCommission.total ? salesCommission.total.toFixed(2) : '0.00'}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default CommissionReport