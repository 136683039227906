import axios from "axios";
import { ADD_SALES_REPORT_FAIL, ADD_SALES_REPORT_REQUEST, ADD_SALES_REPORT_SUCCESS, CLEAR_ERRORS, GET_SALES_REPORT_FAIL, GET_SALES_REPORT_REQUEST, GET_SALES_REPORT_SUCCESS } from "../../types/salesTypes/salesReport";

// Add Sales Report
export const addSalesReport = (reportData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_SALES_REPORT_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/addsalesreport`,
            reportData,
            config
        );

        dispatch({
            type: ADD_SALES_REPORT_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ADD_SALES_REPORT_FAIL,
            payload: error
        });
    }
};

// Get Sales Person Report
export const getSalesReport = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_SALES_REPORT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getsalesreport/${id}`,
            config
        );

        dispatch({
            type: GET_SALES_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_SALES_REPORT_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clear All Errors
export const clearReportErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}