// Sales User Details Type
export const SALES_USER_DETAIL_REQUEST = "SALES_USER_DETAIL_REQUEST";
export const SALES_USER_DETAIL_SUCCESS = "SALES_USER_DETAIL_SUCCESS";
export const SALES_USER_DETAIL_FAIL = "SALES_USER_DETAIL_FAIL";

// Update Sales user Type
export const UPDATE_SALES_REQUEST = "UPDATE_SALES_REQUEST";
export const UPDATE_SALES_SUCCESS = "UPDATE_SALES_SUCCESS";
export const UPDATE_SALES_RESET = "UPDATE_SALES_RESET";
export const UPDATE_SALES_FAIL = "UPDATE_SALES_FAIL";

// Update Sales user password Type
export const UPDATE_SALES_PASSWORD_REQUEST = "UPDATE_SALES_PASSWORD_REQUEST";
export const UPDATE_SALES_PASSWORD_SUCCESS = "UPDATE_SALES_PASSWORD_SUCCESS";
export const UPDATE_SALES_PASSWORD_RESET = "UPDATE_SALES_PASSWORD_RESET";
export const UPDATE_SALES_PASSWORD_FAIL = "UPDATE_SALES_PASSWORD_FAIL";

// Sales User Delivery Report
export const SALES_DELIVERY_REPORT_REQUEST = "SALES_DELIVERY_REPORT_REQUEST";
export const SALES_DELIVERY_REPORT_SUCCESS = "SALES_DELIVERY_REPORT_SUCCESS";
export const SALES_DELIVERY_REPORT_FAIL = "SALES_DELIVERY_REPORT_FAIL";

// Delivery Filter Year Report
export const DELIVERY_YEAR_REQUEST = "DELIVERY_YEAR_REQUEST";
export const DELIVERY_YEAR_SUCCESS = "DELIVERY_YEAR_SUCCESS";
export const DELIVERY_YEAR_FAIL = "DELIVERY_YEAR_FAIL";

// Sales User Commission Report
export const SALES_COMMISSION_REPORT_REQUEST = "SALES_COMMISSION_REPORT_REQUEST";
export const SALES_COMMISSION_REPORT_SUCCESS = "SALES_COMMISSION_REPORT_SUCCESS";
export const SALES_COMMISSION_REPORT_FAIL = "SALES_COMMISSION_REPORT_FAIL";

// Commission Filter Year Report
export const COMMISSION_YEAR_REQUEST = "COMMISSION_YEAR_REQUEST";
export const COMMISSION_YEAR_SUCCESS = "COMMISSION_YEAR_SUCCESS";
export const COMMISSION_YEAR_FAIL = "COMMISSION_YEAR_FAIL";

// Sales Profile Details Page Type
export const SALES_PROFILE_DETAIL_REQUEST = "SALES_PROFILE_DETAIL_REQUEST";
export const SALES_PROFILE_DETAIL_SUCCESS = "SALES_PROFILE_DETAIL_SUCCESS";
export const SALES_PROFILE_DETAIL_FAIL = "SALES_PROFILE_DETAIL_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RESET_SALES = "RESET_SALES";