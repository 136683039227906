import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDrd-v6hVRsv_EQMEFWbrYnHa4yssuLQOQ",
  authDomain: "oddsdrive-firebase.firebaseapp.com",
  projectId: "oddsdrive-firebase",
  storageBucket: "oddsdrive-firebase.firebasestorage.app",
  messagingSenderId: "111943108350",
  appId: "1:111943108350:web:f3c199aa40c3c0bd1e87af"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);