import { useRef, useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deliveryReportSales, deliveryYearSales } from "../../../../store/actions/salesActions/salesUsers";
import moment from "moment";
import csvIcon from '../../../../assests/images/csv-icon.png';
import pdfIcon from '../../../../assests/images/pdf-icon.png';
import { Col, Form } from "react-bootstrap";
import generatePDF, { Margin } from 'react-to-pdf';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';

const DeliveryReport = () => {

    const dispatch = useDispatch();
    const reportDeliveryRef = useRef(null);

    const { salesUserDetail, loading } = useSelector((state) => state.salesUserDetails);
    const { salesDelivery } = useSelector((state) => state.salesDeliveryReport);
    const { deliveryYearArr } = useSelector((state) => state.deliveryYears);

    const [deliveryYear, setDeliveryYear] = useState('');
    const [deliveryMonth, setDeliveryMonth] = useState('');

    // submit form
    const handleReportSubmit = (e) => {
        e.preventDefault();
        dispatch(deliveryReportSales(salesUserDetail.user.customer_id, deliveryYear, deliveryMonth));
    }
    
    // handle year change event
    const handleYearChange = (e) => {
        setDeliveryYear(e.target.value);
        dispatch(deliveryReportSales(salesUserDetail.user.customer_id, e.target.value, deliveryMonth));
    }
    
    // handle month change event
    const handleMonthChange = (e) => {
        setDeliveryMonth(e.target.value);
        dispatch(deliveryReportSales(salesUserDetail.user.customer_id, deliveryYear, e.target.value));
    }

    // PDF options
    const options = {
        method: 'open',
        filename: 'delivery_report.pdf',
        page: {
           margin: Margin.MEDIUM,
           format: 'A4',
           orientation: 'portrait',
        },
        overrides: {
            canvas: {
                onclone: async function (doc) {
                    const tdList = doc.querySelectorAll(".report_table tr td");
                    for (let i = 0; i < tdList.length; i++) {
                        tdList[i].style.padding = "0 8px 18px";
                    }
                    const thList = doc.querySelectorAll(".report_table tr th");
                    for (let i = 0; i < thList.length; i++) {
                        thList[i].style.padding = "0 8px 18px";
                    }
                }
            }
        },
    };

    // sales delivery export data
    const mappedData = salesDelivery && salesDelivery.records && salesDelivery.records.length >= 1 && salesDelivery.records.map(item => {
        return [
            moment(item.date_added).format("MM/DD/YYYY"),
            item.restaurant_name,
            item.last_7_day,
        ];
    });

    // Export as CSV
    const generateCSV = () => {

        const worksheet = XLSX.utils.json_to_sheet(mappedData, { origin: 'A2', skipHeader: true });
            
        const Heading = [
            ['Date Registered', 'Business Name', 'Last 7 days deliveries']
        ];
    
        XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });
    
        // Convert the worksheet to CSV format
        const csv = XLSX.utils.sheet_to_csv(worksheet);
    
        // Create a Blob from the CSV string and download
        const blob = new Blob([csv], { type: 'text/csv' });
        FileSaver.saveAs(blob, `delivery_report_${moment().unix()}.csv`);
            
    }

    useEffect(() => {
        if(loading === false && salesUserDetail && salesUserDetail.status === 1){
            dispatch(deliveryReportSales(salesUserDetail.user.customer_id, '', ''));
            dispatch(deliveryYearSales(salesUserDetail.user.customer_id));
        }
    
    }, [dispatch, loading, salesUserDetail])

    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <div className="reports_filter_section" style={{display: 'none'}}>
                <div className="reports_filter_left">
                    <Form className="sales-add-report-form business_profile_form" method="post" onSubmit={handleReportSubmit}>

                        <div className="flex flex-col md:flex-row gap-[20px]">
                            <Form.Group as={Col} controlId="report_delivery_year">
                                <Form.Label>Select Year</Form.Label>
                                <Form.Select name="report_delivery_year" value={deliveryYear} onChange={(e) => handleYearChange(e)} className="report_filter_input">
                                    <option value="">Select Year</option>
                                    {deliveryYearArr && deliveryYearArr.length >= 1 &&
                                        deliveryYearArr.map((year,i) => (
                                            <option value={year} key={i}>{year}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="report_delivery_month">
                                <Form.Label>Select Month</Form.Label>
                                <Form.Select name="report_delivery_year" value={deliveryMonth} onChange={(e) => handleMonthChange(e)} className="report_filter_input">
                                    <option value="">Select Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                            </Form.Group>

                        </div>

                    </Form>
                </div>

                <div className="reports_filter_right">
                    <img src={csvIcon} alt="CSV" onClick={() => generateCSV()} />
                    <img src={pdfIcon} alt="CSV" onClick={() => generatePDF(reportDeliveryRef, options)} />
                </div>
            </div>
            

            <Table ref={reportDeliveryRef} className="report_table" id="delivery_report_table">
                <thead>
                    <tr>
                        <th className="text-center" style={{width: '20%'}}>Date Registered</th>
                        <th style={{width: '30%'}}>Business Name</th>
                        <th className="text-center" style={{width: '50%'}}>
                            Deliveries
                            <div className="deliveries_block">
                                {/* <span>Today</span> */}
                                <span>Last 7 days</span>
                                {/* <span>This Month</span> */}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {salesDelivery && salesDelivery.records && salesDelivery.records.length >= 1 ? 
                        salesDelivery.records.map((item,i) => (
                            <tr className={ i%2 ===0 ? "grey": "white" } key={i}>
                                <td data-label="Date" className="text-center">{moment(item.date_added).format("MM/DD/Y")}</td>
                                <td data-label="Business Name">{item.restaurant_name} </td>
                                <td data-label="Deliveries" className="text-center">
                                    <div className="deliveries_block">
                                        {/* <span><span className="deliveries_block_title">Today</span> {item.today_deliveries}</span> */}
                                        <span><span className="deliveries_block_title">Last 7 days</span> {item.last_7_day}</span>
                                        {/* <span><span className="deliveries_block_title">This Month</span> {item.this_month}</span> */}
                                    </div>
                                </td>
                            </tr>
                        ))
                    : 
                        <tr>
                            <td colSpan={3}>No Record Found</td>
                        </tr>
                    }
                    
                    {/* <tr className="tfoot">
                        <td colSpan="3" data-label="Total Deliveries" className="text-right total_deliveries_foot"><span className="hide-mobile">Total No. of Deliveries:</span> 0</td>
                    </tr> */}
                </tbody>
            </Table>
        </div>
    )
}

export default DeliveryReport